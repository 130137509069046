import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"technology"}},[_c('ws-app-bar',{attrs:{"dark":""}}),_c(VSheet,{staticClass:"d-flex flex-column justify-space-between",attrs:{"color":"ws-black","dark":"","elevation":"0"}},[_c(VImg,{staticClass:"flex-grow section-md hero-technology",attrs:{"eager":"","aspect-ratio":"4","position":_vm.$vuetify.breakpoint.mdAndUp ? '100% 60%' : 'center center',"src":require('../assets/new-site/technology/hero.png'),"xgradient":"to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)","gradient":_vm.$vuetify.breakpoint.mdAndUp
          ? 'to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)'
          : 'to right, rgba(12 ,25 ,34, .7),  rgba(12 ,25 ,34, .7)'}},[_c(VContainer,{staticClass:"py-14"},[_c(VRow,{attrs:{"wrap":""}},[_c(VCol,{staticClass:"px-8 px-lg-0",attrs:{"cols":"12","sm":"6","md":"8","lg":"6"}},[_c('h1',{staticClass:"mb-4 hidden-sm-and-down"},[_c('ws-gradient-text',{staticClass:"text-uppercase"},[_vm._v(" Optimizing your entire "),_c('br'),_vm._v(" supply chain with AI. ")])],1),_c('h1',{staticClass:"mb-8 hidden-md-and-up"},[_vm._v(" Optimizing your entire "),_c('br'),_vm._v(" supply chain with AI. ")]),_c('p',{staticClass:"body-1 my-6 hidden-sm-and-down"},[_vm._v(" Manage your entire logistics with the touch of a button. Our technology is designed to power your success through process simplification and efficiency. Save yourself the hassle and the money and get your logistics sorted from one platform. ")]),_c('div',[_c(VBtn,{staticClass:"mr-3",attrs:{"color":"white","light":""},on:{"click":function($event){_vm.logGAEvent('Technology', 'Get a quote', "Hero Banner");
                  _vm.$vuetify.goTo('#interest');}}},[_vm._v(" Get a Quote ")])],1)])],1)],1)],1)],1),_c('section',{staticClass:"technology"},[_c(VContainer,[_c(VRow,{staticClass:"d-flex align-items-center flex-column-reverse flex-lg-row mb-4"},[_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c('h1',{staticClass:"mb-4"},[_c('ws-gradient-text',[_vm._v(" What can you get done through our platform? ")])],1),_c('p',{staticClass:"main-desc"},[_vm._v("In short, everything. In detail as below:")])]),_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c(VImg,{staticClass:"hidden-sm-and-down",attrs:{"eager":"","contain":"","src":require("../assets/new-site/technology/screen1.png"),"alt":"screen"}}),_c(VImg,{staticClass:"hidden-md-and-up",attrs:{"eager":"","contain":"","src":require("../assets/new-site/technology/mobile-screen1.png"),"alt":"screen"}})],1)],1),_vm._l((_vm.technologies),function(item,i){return _c(VRow,{key:i,staticClass:"d-flex align-items-center flex-column-reverse flex-lg-row mb-4",class:{ 'ws-odd-row': i % 2 }},[_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"d-flex align-items-start flex-row flex-md-column"},[_c(VIcon,{attrs:{"color":"accent","size":"70"}},[_vm._v("$vuetify.icons."+_vm._s(item.icon))]),_c('h4',{staticClass:"my-3"},[_vm._v(_vm._s(item.title))])],1),_c(VList,{staticClass:"transparent",attrs:{"dense":""}},_vm._l((item.desc),function(p){return _c(VListItem,{key:p,staticClass:"pl-0"},[_c(VListItemIcon,{staticClass:"mr-2 mt-3"},[_c(VIcon,{attrs:{"color":"accent"}},[_vm._v("mdi-check")])],1),_c(VListItemContent,[_c('span',{staticClass:"desc"},[_vm._v(_vm._s(p))])])],1)}),1),_c(VBtn,{staticClass:"p-0 ml-4 ml-md-2 text-capitalize",attrs:{"light":"","text":"","color":"accent"},on:{"click":function($event){_vm.logGAEvent('Technology', 'Get a quote', "Technology Page");
              _vm.$vuetify.goTo('#interest');}}},[_c('span',{staticClass:"text-decoration-underline"},[_vm._v(_vm._s(item.btn))]),_c(VIcon,{staticClass:"mt-1",attrs:{"size":"18"}},[_vm._v("mdi-arrow-right")])],1)],1),_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c(VImg,{staticClass:"hidden-sm-and-down",attrs:{"eager":"","contain":"","src":item.image,"alt":"screen"}}),_c(VImg,{staticClass:"hidden-md-and-up mb-5",attrs:{"eager":"","contain":"","src":item.mobileImage,"alt":"screen"}})],1)],1)})],2)],1),_c('section',{staticClass:"ws-banner ws-banner-pink section-lg white--text",attrs:{"id":"interest"}},[_c(VContainer,{attrs:{"dark":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('h2',{staticClass:"mb-6"},[_vm._v(" We Store."),_c('br'),_vm._v(" You Grow. ")]),_c('h3',{staticClass:"mb-5"},[_vm._v(" Solving the hassles of your storage and delivery. ")]),_c('h4',[_vm._v("We will do the heavy lifting for you!")])]),_c(VCol,{attrs:{"cols":"12","md":"6","lg":"4","offset-lg":"1"}},[_c('ws-common-form',{attrs:{"title":"Get a Call","title-color":"primary","color":"white","light":"","comments-label":"Comments / Questions","submit-color":"ws-green","form-type":_vm.types.CustomerInterest}})],1)],1)],1)],1),_c('ws-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }