

































































































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";

export default mixins(MyMixin).extend({
  name: "Technology",
  mounted() {
    this.trackMyPageView();
  },
  metaInfo: {
    title: metadata.Technology.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.Technology.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  computed: {
    types() {
      return types;
    },
  },
  data: () => ({
    technologies: [
      {
        title: "An integrated sales system",
        desc: [
          "Keep track of your orders from all online channels.",
          "Be able to prioritize orders.",
          "Manage cancelations, refunds, and returned items.",
        ],
        icon: "integratedSales",
        btn: "Get A Demo",
        image: require("@/assets/new-site/technology/screen2.png"),
        mobileImage: require("@/assets/new-site/technology/mobile-screen2.png"),
      },
      {
        title: "Inventory management",
        desc: [
          "Track, reduce, increase, and reserve your stock instantly and on demand.",
          "Set out-of-stock notifications.",
          "Keep an overview of your inventory at multiple warehouses",
          "Monitor the transportation of your inventory in real time.",
        ],
        icon: "catalog",
        btn: "Request A Quote",
        image: require("@/assets/new-site/technology/screen3.png"),
        mobileImage: require("@/assets/new-site/technology/mobile-screen3.png"),
      },
      {
        title: "Reporting system",
        desc: [
          "Create custom, inventory, sales, and returns reports.",
          "Calculate your revenue and profits",
        ],
        icon: "reporting",
        btn: "Get A Demo",
        image: require("@/assets/new-site/technology/screen4.png"),
        mobileImage: require("@/assets/new-site/technology/screen4.png"),
      },
      {
        title: "Transportation/freight management",
        desc: [
          "Pick, tailor, and monitor your transportation and freight services.",
          "Track the location and delivery timings of your products.",
          "Upload and download all necessary customs documentation.",
          "Print shipping labels and create packing lists.",
        ],
        icon: "tracking",
        btn: "Request A Quote",
        image: require("@/assets/new-site/technology/screen5.png"),
        mobileImage: require("@/assets/new-site/technology/screen5.png"),
      },
    ],
  }),
});
